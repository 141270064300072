import { IPersonaProps, PersonaPresence } from '@fluentui/react';
import { availabilityToPersonaPresence, UserAvailability } from 'kits/availabilityKit';
import IvicosStrings from 'kits/language/stringKit';
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { idpService } from 'services/api/identity-provider.service';
import { resourceService } from 'services/api/resource.service';
import { aAPILanguage, aAreaList, aCampus, aOrgBranding, aReviewInvitations, aRoomList, aRoomListStyles, aSelectedVisitor } from 'shared-state/directory/atoms';
import { sArea, sAreaListWithCount, sPersonalRoomList, sRoom, sRoomListIncludingPersonalRooms, sStyledRoomList } from 'shared-state/directory/selectors';
import { IProfile } from 'shared-state/identity/types';

export const useUILanguage = (): any => {
    return useRecoilState(aAPILanguage);
};

export const useReviewInvitations = (): any => {
    return useRecoilState(aReviewInvitations);
};

export const useSelectedVisitor = (): any => {
    return useRecoilState(aSelectedVisitor);
};

export const useRoomList = (): any => {
    return useRecoilState(aRoomList);
};
export const useRoomListIncludingPersonalRooms = (): any => {
    return useRecoilValue(sRoomListIncludingPersonalRooms);
};
export const useRoomListStyles = (): any => {
    return useRecoilState(aRoomListStyles);
};
export const useStyledRoomList = (): any => {
    return useRecoilValue(sStyledRoomList);
};
export const usePersonalRoomList = (): any => {
    return useRecoilValue(sPersonalRoomList);
};

export const useRoom = (roomId: string): any => {
    return useRecoilState(sRoom(roomId));
};

export const useCampus = (): any => useRecoilState(aCampus);
export const useAreaList = (): any => useRecoilState(aAreaList);
export const useAreaListWithCount = (): any => useRecoilValue(sAreaListWithCount);
export const useArea = (areaId?: string): any => {
    return useRecoilState(sArea(areaId));
};
export const useCurrentOrgBranding = (): any => {
    return useRecoilState(aOrgBranding);
};

export const convertInvitationLists = (invitation: any): any => {
    const { event_name, type, open_from, open_to, inviterSub, default_destination, welcome_message, invitationId, repeat, custom_url, customized_video_url } =
        invitation;

    const hosts: any[] = [];
    if (inviterSub) {
        if (Array.isArray(inviterSub)) {
            inviterSub.map((i: any) => hosts.push(i.split(':')[2]));
        } else {
            hosts.push(inviterSub.split(':')[2]);
        }
    }

    let openDate;
    let openTime;
    if (open_from > 0) {
        openDate = new Date(open_from * 1000);
        openTime = openDate ? openDate.toString().split(' ')[4] : 0;
    }

    let toDate;
    let toTime;
    if (open_to > 0) {
        toDate = new Date(open_to * 1000);
        toTime = toDate ? toDate.toString().split(' ')[4] : 0;
    }
    const invitationDetails = {
        name: event_name,
        validity: type,
        validFromDate: openDate,
        validFromTime: openTime,
        validToDate: toDate,
        validToTime: toTime,
        repeat: repeat,
        hosts: hosts && hosts,
        type: default_destination?.areaId ? 'direct' : 'pickup',
        area: default_destination?.areaId && default_destination?.areaId,
        room: default_destination?.roomId && default_destination?.roomId,
        message: welcome_message === 'wElCoMe_MeSsAgE' ? '' : welcome_message,
        invitationId: invitationId,
        customUrl: custom_url,
        customizedVideoUrl: customized_video_url
    };
    return invitationDetails;
};

export const useFetchInvitationLists = (setterFunc?: React.Dispatch<React.SetStateAction<any>>): any => {
    const fetchInvitations = async () => {
        const invitationDTOs: any = await idpService.showUserInvitationsList();
        const invitationDetailLists: any[] = [];
        if (invitationDTOs) {
            invitationDTOs.invitations.forEach((invitation: any) => invitationDetailLists.push(convertInvitationLists(invitation)));
            if (setterFunc) {
                setterFunc(invitationDetailLists);
            }
        }
    };
    fetchInvitations();
};

export const useFetchRoomLists = (
    campusId: string,
    areaId: string,
    currentUserId: string,
    setterFunc?: React.Dispatch<React.SetStateAction<any>>,
    userListForRoomSelection?: IProfile[]
): any => {
    // Helper function to convert room data into a simpler format
    const convertRoomsToLists = (room: any): any => {
        const { id, name, iconKey, isAudioOnly, whitelist, isPrivate } = room;
        const usersInRoom = userListForRoomSelection?.filter((user) => user.room === id) || [];
        return {
            key: id,
            text: name,
            icon: iconKey,
            video: isAudioOnly,
            isWhitelisted: whitelist && Array.isArray(whitelist) && isPrivate,
            users: usersInRoom
        };
    };

    const fetchRooms = async () => {
        // Return early if any of the required parameters are missing
        if (!campusId || !areaId || !currentUserId) return;
        try {
            const roomDTOs: any[] = await resourceService.getAllForArea(campusId, areaId);

            // Filter rooms that are open for visitors and accessible by the current user
            const roomDetailLists = roomDTOs
                .filter((room: any) => room.isOpenForVisitors)
                .filter((room: any) => {
                    // If room has a non-empty whitelist, check if user has access
                    if (room.whitelist && room.whitelist.length > 0) {
                        return currentUserId === room.creatorId || room.whitelist.includes(currentUserId);
                    }
                    // If no whitelist or empty whitelist, allow access
                    return true;
                })
                .map((room: any) => convertRoomsToLists(room))
                .sort((a, b) => a.text.localeCompare(b.text));
            // Update the room list using setterFunc if provided
            if (setterFunc) {
                setterFunc(roomDetailLists);
            }
        } catch (error) {
            console.error('Error fetching room lists:', error);
        }
    };

    fetchRooms();
};

export const useFetchMembers = (availability: UserAvailability | undefined, setterFunc?: React.Dispatch<React.SetStateAction<any>>): any => {
    const convertMemberToPersona = (member: any): IPersonaProps => {
        const { userId, profile_pic, name } = member;

        const personaDetails = {
            id: userId,
            primaryText: name,
            imageUrl: profile_pic,
            presence: (availability && availabilityToPersonaPresence(availability)) || PersonaPresence.online
        };
        return personaDetails;
    };

    const fetchUsers = async () => {
        const fetchedMembers = await idpService.showMembers();
        const memberPersona: IPersonaProps[] = [];
        if (fetchedMembers) {
            fetchedMembers.forEach((member: any) => memberPersona.push(convertMemberToPersona(member)));

            if (setterFunc) {
                setterFunc(memberPersona);
            }
        }
    };

    fetchUsers();
};

export const useDateToTimeStamp = (date: Date, time: string): any => {
    const timeInHour = Number(time.split(':')[0]);
    const timeInMinutes = Number(time.split(':')[1]);
    const givenDateWithTime = new Date(date.getFullYear(), date.getMonth(), date.getDate(), timeInHour, timeInMinutes, 0);
    const givenDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);

    const currentDate: any = new Date();

    const currentDateWithTime = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0).getTime();

    if (givenDate && givenDateWithTime && currentDateWithTime) {
        if (givenDate.getTime() - currentDateWithTime >= 0) {
            return givenDateWithTime.getTime() * 0.001;
        } else {
            // this has been built for testing purposes and cannot be triggered (can be removed)
            // alert(IvicosStrings.invalidDataAlert);
            return;
        }
    }
};

export const useGetSpecificDay = (dayName: any, fromTime: string, toTime: string): any => {
    // The current day
    const currentDate = new Date();
    const today = currentDate.getDay();

    // Days of the week
    const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

    // The index for the day you want
    const givenDay = typeof dayName === 'string' ? days.indexOf(dayName.toLowerCase()) : dayName;

    // Find the difference between the current day and the one you want
    // If it's the same day as today (or a negative number), jump to the next week
    let differenceBetweenDays = givenDay - today;
    differenceBetweenDays = differenceBetweenDays < 0 ? 7 + differenceBetweenDays : differenceBetweenDays;

    // Get the timestamp for the desired day
    const timestamp = differenceBetweenDays === 0 ? currentDate.getTime() : currentDate.getTime() + 1000 * 60 * 60 * 24 * differenceBetweenDays;

    // Get the next day
    const dateOfTheGivenDay = new Date(timestamp);

    const timeStampOfFromTime = useDateToTimeStamp(dateOfTheGivenDay, fromTime);
    const timeStampOfToTime = useDateToTimeStamp(dateOfTheGivenDay, toTime);
    if (timeStampOfFromTime && timeStampOfToTime) {
        if (timeStampOfToTime - timeStampOfFromTime > 0) {
            return { timeStampOfFromTime, timeStampOfToTime };
        } else {
            //alert('Given time range is invalid');
            return;
        }
    }
};

export const useTruncateDisplayName = (str: string, limit: number): string => {
    if (str.includes(IvicosStrings.personalRoomTitle)) {
        const name = str.split(IvicosStrings.personalRoomHelper)[0];
        if (name.slice(-1) === 's') {
            const filteredName = name.substring(0, name.length - 1);
            if (filteredName.length > limit) {
                const truncatedName = filteredName.substring(0, limit) + '...';
                return `${truncatedName}${IvicosStrings.personalRoomTitle}`;
            } else {
                return `${filteredName}${IvicosStrings.personalRoomTitleForNameEndingWithS}`;
            }
        }
        if (name.length > limit) {
            const truncatedName = name.substring(0, limit) + '...';
            return `${truncatedName}${IvicosStrings.personalRoomTitle}`;
        } else {
            return str;
        }
    } else {
        if (str.length > limit) {
            return str.substring(0, limit) + '...';
        } else {
            return str;
        }
    }
};
